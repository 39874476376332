<template>
    <o-data-lookup :data-object="dsDailyLogEntries">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
        </template>
        <o-column field="ID" width="100"></o-column>
        <o-column field="Description" width="200"></o-column>
        <o-column field="EntryDateAndTime" width="150"></o-column>
        <o-column field="CreatedBy" width="150"></o-column>
        <o-column field="OrgUnit" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>
import { getOrCreateDataObject } from 'o365.vue.ts'

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsDailyLogEntries = getOrCreateDataObject({
    id: 'dsDailyLogEntries' + crypto.randomUUID(),
    viewName: 'aviw_Complete_DailyLogEntries',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields: [
        { name: "ID", type: "string" },
        { name: "Description", type: "string" },
        { name: "EntryDateAndTime", type: "datetime" },
        { name: "OrgUnit", type: "string" },
        { name: "CreatedBy", type: "string" },
    ]
});
</script>
